@charset 'utf-8';
@import 'colours';
@import 'typography';
@import 'debug';

$kev_font: "Oswald", sans-serif;
// $kev_font: "Lato", sans-serif;

html {

  text-rendering: optimizeLegibility;
}

.cover {
  height: 200px;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}

nav {

  // background-color: $kevred;
  .nav-link {
    font-family: "Lato", sans-serif;
    font-size: 12pt;
    color: $kevred;
  }

  .nav-link:hover {
    color: #FFCA29;
  }

  .navbar-brand {
    color: $kevred;
  }

  .brand {
    color: $kevred;
    font-size: 30px;

  }

  .navbar-brand:hover,
  .brand:hover {
    color: #FFCA29;
  }
}

.navbar-toggler {
  color: $kevred;
  border: 1px solid $kevred !important;
}

.breadcrumb {
  background-color: white;

  a {
    color: $kevred;
    text-decoration: none;
  }

}

li.breadcrumb-item.black.a {
  color: black !important;
  text-decoration: none;
}

li.breadcrumb-item.white.a {
  color: white !important;
  text-decoration: none;
}

li.breadcrumb-item.white::before {
  color: white !important;
  text-decoration: none;
}

li.breadcrumb-item.black::before {
  color: black !important;
  text-decoration: none;
}

li.breadcrumb-item.white {
  a {
    color: white !important;
    text-decoration: none;
  }
}

li.breadcrumb-item.black {
  a {
    color: black !important;
    text-decoration: none;
  }
}

.breadcrumb-item::before {
  color: $kevred !important;
}

.top1 {
  margin-top: 10px;
  overflow: hidden;
}

.top1 img {
  height: 100%;
}

.bg-red {
  background-color: #C0342B;
  color: white;
}

.border-bg-red {
  border-bottom: 3px solid #C0342B;
  color: white;

}

.bg-white {
  background-color: white;
  color: black;
}

.bg-orange {
  background-color: #fd9c00;
  color: white;
}

.bg-green {
  background-color: #59C960;
  color: white;
}

.bg-black {
  background-color: rgba(0, 0, 0, 0.879);
  // a {
  //   color: white;
  //   background-color: rgba(0, 0, 0, 0.879);
  //   text-decoration: none;
  //   bottom: 0px;
  //   right: 0px;
  //   position: absolute;
  //   overflow: hidden;
  //   text-overflow: ellipsis;
  //   text-wrap: normal;
  //   padding: 6px;
  //   width: 70px;
  // }
}

.bg-darkgrey {
  background-color: rgba(0, 0, 0, 0.879);

  a {
    color: white;
    background-color: rgba(0, 0, 0, 0.879);
    text-decoration: none;
    bottom: 0px;
    right: 0px;
    position: absolute;
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: normal;
    padding: 6px;
    width: 70px;
  }
}

.card.bg-ocre {
  background-color: #FFCA29;
  color: black;
}

$transition_speed_in: 0.15s;  // Faster transition for hover in
$transition_speed_out: 0.3s;  // Slower transition for hover out
$shadow_amount: 0.3;

.shadow-hover {
  transition: box-shadow $transition_speed_out ease-in-out; // Slower when moving away from the element
  box-shadow: 0 0 0 rgba(0, 0, 0, 0); // Set an initial transparent shadow
}

// Custom CSS for hover effect
.shadow-hover:hover {
  transition: box-shadow $transition_speed_in ease-in-out; // Faster when hovering over
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, $shadow_amount);
}

// Custom CSS for hover effect with transition
.card-hover {
  transition: transform $transition_speed_out ease-in-out, box-shadow $transition_speed_out ease-in-out; // Slower transition when moving away
  box-shadow: 0 0 0 rgba(0, 0, 0, 0); // Initial state with an invisible shadow
}

.card-hover:hover {
  transition: transform $transition_speed_in ease-in-out, box-shadow $transition_speed_in ease-in-out; // Faster transition when hovering
  transform: scale(1.05); // Slightly increase the size
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, $shadow_amount); // Visible shadow
}

.text-black {
  color: black;
}

.sitemap {
  h5 {
    font-size: 12pt;
    font-weight: 500;
    color: white;
  }

  a {
    color: rgba(248, 248, 255, 0.878);
    font-size: 11pt;
    font-weight: 400;
    text-decoration-line: none;
    // text-decoration-line: underline;
    // text-decoration-style: dotted;
    text-decoration-color: whitesmoke;
    margin-bottom: 30px;

  }

  ul li a {
    margin-bottom: 4px;
    padding-bottom: 0px;
    // text-decoration-line: underline;
    // text-decoration-style: dotted;
    text-decoration-line: none;
    text-decoration-color: whitesmoke;

  }
}

.black {
  color: black !important;
}

.media {
  @media screen and (min-width: 300px) {
    width: 100px;
  }

  @media screen and (min-width: 768px) {
    width: 200px;
  }
}

.grey {
  color: gray;
  text-decoration: none;
}

.bg-nav {
  background-color: #00ccff;
  border-radius: 4pt;
}

.bg-nav a {
  color: white;
}

.bg-nav ul li a.active {

  // background-color: #C0342B;
  color: rgb(255, 255, 6);
  // border-bottom-left-radius: 4pt;
  // border-bottom-right-radius: 4pt;
}

.bg-nav a:hover {
  color: #FFCA29;
}

.bg-yellow {
  background-color: #FFCA29;
}

.thanks a.nav-link {
  color: #295EFF;
  text-emphasis-style: bold;
  font-weight: 700;
}

.menu-title {
  text-decoration-line: underline;
  text-decoration-style: dotted !important;
  text-decoration-line: 1px;
}

.dotted {
  text-decoration-style: dotted !important;
  text-decoration-line: 1px;

  // color:black;
  color: $orange ;

}

.dotted-white {
  color: white;
  text-decoration-style: dotted !important;
  text-decoration-line: 1px;
}

.blog-part {
  .title {
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.9);
    font-size: 20pt;
  }
}

h1 {
  // font-family: 'Vox', sans-serif;
  font-family: $kev_font;
  letter-spacing: -3px;
  text-transform: uppercase;
}

h2 {
  letter-spacing: -1px;
  text-transform: uppercase;
  font-family: $kev_font;
}

h1 {
  font-size: 50pt !important;
  font-stretch: condensed !important;
  font-weight: 800;
  // color: $kevred;
}

article {

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: $kev_font, sans-serif;
    text-transform: uppercase;
  }

  h2 {
    font-size: 30pt;
  }

  #markdown-toc {
    list-style: none;
    margin-left: 0px;
    padding-left: 0px;

    li a {
      color: $orange;
      text-decoration-style: dotted;
    }
  }

  .toc {
    ul {
      list-style: none;
      margin-left: 0px;
      padding-left: 0px;

      li a {
        color: $orange;
        text-decoration-style: dotted;
      }
    }

    em {
      font-weight: 600;
    }
  }

  li a {
    color: $orange;
    text-decoration-style: dotted;
  }

  .icon {
    width: 48px;
    height: 48px;
  }

}

.note {
  background-color: #FFCA29;

  p {
    color: black;
    text-decoration-color: black;
    font-size: 12pt;

  }

}

a.nav-link.white-text:hover {
  color: #FFCA29;
}

.small-nav {
  font-size: 10pt !important;
}

.nav-link.small-nav {

  color: $orange;

}

.nav-link.small-nav.active {
  font-weight: 600;
  background-color: whitesmoke !important;
  color: #0088ff;
}

a.nav-link.white-text {
  color: white;
}

nav-vox {
  font-family: vox, sans-serif;
  font-weight: 700;
  font-style: normal;
}

.sub-nav {
  background-color: $kevred;

}

.sub-nav a {
  font-size: 12pt;
  color: gray;
}

hr {
  color: lightgrey !important;
  height: 1px !important;
}

.orange {
  color: $orange !important;
  text-decoration: none;
}

a.dotted {
  text-decoration-style: dotted !important;
  text-decoration-color: $orange !important;
  text-decoration-line: 1px !important;
  min-width: 0;
  
}

h5 {
  font-family: $kev_font, sans-serif;
  font-size: 17pt !important;
  text-transform: uppercase;
}

.bg-white {
  background-color: white;
}

@media (max-width: 1250px) {

  /* … */
  #small_nav {
    visibility: hidden;
  }
}

@media (min-width: 1249px) {

  #small_nav {
    visibility: visible;
  }
}

.top-nav a:hover {
  background-color: #FFCA29;
  color: black !important;
  border-radius: 5px;
  transition: ease 0.5s;
}

i {
  width: 16px !important;
  height: inherit;
}

.navbar .megamenu {
  padding: 10px;
  transition: ease 0.5s;
}

/* ============ desktop view ============ */
@media all {

  .navbar .has-megamenu {
    position: static !important;
  }

  .navbar .megamenu {
    left: 0;
    right: 0;
    width: 100%;
    margin-top: 0;
  }

}

/* ============ desktop view .end// ============ */

/* ============ mobile view ============ */
// @media(max-width: 991px){
//   .navbar.fixed-top .navbar-collapse, .navbar.sticky-top .navbar-collapse{
//     overflow-y: auto;
//       max-height: 90vh;
//       margin-top: 0px;
//   }
// }
/* ============ mobile view .end// ============ */

ul.nav li.nav-link {
  // background-color: #00000005;
  border-radius: 5px;
  padding: 5px;
}

ul.nav li.nav-link a.nav-item.dotted {
  text-decoration-style: dotted !important;
  text-decoration-line: 1px !important;
  // text-decoration-color: $orange !important;
  color: $orange ;
  font-family: Lato, sans-serif;


}

ul.nav li.nav-link.active,
ul.nav li.nav-link.active a.nav-item.dotted {
  font-weight: 700 !important;
  background-color: $orange !important;
  color: white !important;
  border-radius: 3px;
  padding-left: 10px;
}

.bg-learnhead {
  background-color: $kevred !important;
  color: white !important;
}

blockquote {

  // border-left: 5px solid $orange;
  border-left: #ffffff60 5px solid;
  padding: 15px;
  padding-bottom: 1px !important;
  font-size: 14pt;
  // font-style: italic;
  color: rgb(255, 255, 255) !important;
  background-color: $orange;
  border-radius: 3px;
  // code {
  //   color: rgb(255, 255, 255) !important;
  // }

  .bg-blue {
    background-color: $clr-blue;
  }

  p,
  ul,
  li {
    color: rgb(255, 255, 255) !important;
  }

  .language-python.highlighter-rouge {
    border-left: #00000060 5px solid;
    // padding: 3px;
  }

  .language-c\+\+.highlighter-rouge {
    border-left: rgb(255, 238, 0) 5px solid;
  }

  code.language-plaintext.highlighter-rouge,
  code.highlighter-rouge {

    color: rgb(255, 238, 0) !important;
  }

  a {
    color: rgb(255, 255, 255) !important;
    text-decoration-style: dotted !important;
    text-decoration-line: 1px !important;
  }

  .table {
    color: white;
    border-color: white;
  }
}

.language-python.highlighter-rouge {
  border-left: $clr-green 5px solid;
  // padding: 3px;
}

.dotted:hover {
  // color: #FFCA29 !important;
  // background-color: $orange;
  text-decoration-style: dotted !important;
  // text-decoration-color: #FFCA29 !important;
  text-decoration-color: $orange !important;
  // text-decoration-line: 1px !important;
  // font-weight: 800;
  // transition: ease-in-out 0.25s;

  // background-color: #FFCA29;
  // background-color: $orange;
  // color: white !important;
  // border-radius: 5px;
  border-bottom: $orange 3px solid;
  // border-left: $orange 3px solid;
  transition: ease 0.25s;
  // margin: 4px;
  // padding: 4px;
}

.caption {
  font-size: 9pt;
  font-style: italic;
  color: $orange !important;
  // margin-bottom: 2px;
}

.table-code-fit {
  border-color: $clr-lightgrey;
  border-style: solid;
  border-width: 0.5px;
  border-spacing: 4px;
  margin: 0px;

  tr td:first-child {
    background-color: $clr-lightgrey;
    border-color: $clr-lightgrey;
    border-style: solid;
    // border-top: 0px;
    padding: 8px;
    border-width: 0.5px;
    // width: 50%;
    border-left-color: $clr-green;
    border-left-width: 5px;
    border-bottom-color: white;
    border-bottom-width: 1px;
  }

  tr td {
    // background-color: $clr-lightgrey;
    border-color: $clr-lightgrey;
    border-style: solid;
    // border-top: 0px;
    padding: 8px;
    border-width: 1px;
    width: auto;
  }
}

.table-code {
  border-color: $clr-lightgrey;
  border-style: solid;
  border-width: 0.5px;
  border-spacing: 4px;
  margin: 0px;

  tr td:first-child {
    background-color: $clr-lightgrey;
    border-color: $clr-lightgrey;
    border-style: solid;
    // border-top: 0px;
    padding: 8px;
    border-width: 0.5px;
    // width: 50%;
    border-left-color: $clr-green;
    border-left-width: 5px;
    border-bottom-color: white;
    border-bottom-width: 1px;
  }

  tr td {
    // background-color: $clr-lightgrey;
    border-color: $clr-lightgrey;
    border-style: solid;
    // border-top: 0px;
    padding: 8px;
    border-width: 1px;
    width: 50%;
  }

}

.table-5050 {
  width: 100% !important;

  tr td:first-child {
    width: 50% !important;

    .img {
      width: 100% !important;
      ;
      object-fit: cover !important;
    }
  }

  tr td {
    width: 50%;
  }
}

.text-date {
  font-size: 8pt;
  color: black;
  margin-bottom: 2px;
}

.logo {
  width: 48px;
  height: 48px;
}

.scoring-table {
  border-color: $clr-lightgrey;
  border-style: solid;
  border-width: 0.5px;
  border-spacing: 4px;
  margin: 0px;

  tr td:first-child {
    background-color: $clr-lightgrey;
    border-color: $clr-lightgrey;
    border-style: solid;
    // border-top: 0px;
    padding: 8px;
    border-width: 0.5px;
    // width: 50%;
    border-left-color: $clr-green;
    border-left-width: 5px;
    border-bottom-color: white;
    border-bottom-width: 1px;
  }

  tr td {
    // background-color: $clr-lightgrey;
    border-color: $clr-lightgrey;
    border-style: solid;
    // border-top: 0px;
    padding: 4px;
    border-width: 1px;
    // width: 50%;
    font-size: 9pt !important;
  }

  tr th:first-of-type {
    width: 20%;
  }

  tr th:nth-of-type(2) {
    width: 16%;
  }

  tr th:nth-of-type(3) {
    width: 16%;
  }

  tr th:nth-of-type(4) {
    width: 16%;
  }

  tr th:nth-of-type(5) {
    width: 16%;
  }

  tr th:nth-of-type(6) {
    width: 16%;
  }

  tr td.active {
    border-color: $clr-green;
    border-width: 5px;
  }
}

table .w-10 {
  width: 10%;
}

table .w-16 {
  width: 16%;
}

table .w-20 {
  width: 20%;
}

table .w-30 {
  width: 30%;
}

.truncate {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  -moz-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;

}

.text-shadow {
  text-shadow: 0px 0px 6px #000000;
}

.btn.btn-overlay {
  background-color: rgba(255, 255, 255, 0.75);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 0px;
  backdrop-filter: blur(10px);

}

.btn.btn-overlay:hover {
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
}

.bg-grey {
  background-color: #d3d3d3;
}

.btn.btn-overlay-black {
  background-color: rgba(0, 0, 0, 0.75);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 0px;
  backdrop-filter: blur(10px);

}

.btn.btn-overlay-black:hover {
  background-color: rgba(60, 60, 60, 0.75);
  color: #000;
}

.img.img-overlay-black {
  background-color: rgba(0, 0, 0, 0.25);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 0px;
  // backdrop-filter: blur(10px);

}

.img.img-overlay-black:hover {
  background-color: rgba(60, 60, 60, 0.75);
  color: #000;
}

.homepage {
  display: grid;
  grid-template-columns: 50px 50px 50px 50px;
  grid-template-rows: auto;
  grid-template-areas:
    "how_it_works how_it_works how_it_works reviews"
    "glossary robots recent reviews"
    "glossary projects recent courses"
    "blog blog blog twitter"
}

.btn.btn-ocre {
  background-color: #FFCA29;
  color: black !important;
  border-radius: 5px;
  transition: ease 0.25s;
  // margin: 4px;
  // padding: 4px;
}

.btn.btn-outline-ocre {
  border-color: #FFCA29;
  color: black !important;
  border-radius: 5px;
  transition: ease 0.25s;
  // margin: 4px;
  // padding: 4px;
}

.btn.btn-black {
  background-color: black;
  color: #FFCA29 !important;
  border-radius: 5px;
  transition: ease 0.25s;
}

.img-icon {
  max-width: 64px !important;
  height: auto !important;
}

.limited-content {
  display: -webkit-box;
  /* Necessary to use line clamping */
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 2 !important;
  /* Number of lines you want to display */
  overflow: hidden !important;
  line-height: 1.5em;
  /* Define your line-height */
  max-height: 4.5em;
  /* max-height = line-height * number of lines */
}

.limited-title {
  display: -webkit-box;
  /* Necessary to use line clamping */
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 1 !important;
  /* Number of lines you want to display */
  overflow: hidden !important;
  line-height: 1.5em;
  /* Define your line-height */
  max-height: 1.5em;
  /* max-height = line-height * number of lines */
}

.timeline-element {
  position: relative;
  text-align: center;
  padding-top: 0px;
  /* Adjust as needed */
}

.timeline-circle {
  width: 10px;
  /* Adjust size as needed */
  height: 10px;
  /* Adjust size as needed */
  background-color: #000;
  /* Or any color */
  border-radius: 50%;
  display: inline-block;
  position: relative;
  z-index: 1;
}

.timeline-line {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 10px;
  /* Adjust based on circle size */
  height: 20px;
  /* Adjust length as needed */
  border-left: 2px solid #000;
  /* Adjust width and color as needed */
  z-index: 0;
}

.timeline_bottom_line {
  position: relative;
  left: 0;
  right: 0;
  top: 0px;
  /* Adjust based on circle size */
  height: 1px;
  /* Adjust length as needed */
  border-top: 2px solid #000;
  /* Adjust width and color as needed */
  z-index: 0;
}

.pagination-link {
  margin-right: 5px;
  text-decoration: none;
  color: blue;
}

.pagination-link.active {
  font-weight: bold;
}

.footer-link {
  color: white !important;
  text-decoration: none !important;
  display: inline !important;
  text-overflow: ellipsis !important;
  white-space: nowrap;
  overflow: hidden !important;

}

.showcase {
  background-image: url();
  height: 100vh;
  position: absolute;

}

.white {
  color: white !important;
}

.black {
  color: black !important;
}

.blurred-background {
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px); /* for Safari 9+ */
  /* Adjust the blur radius as needed */
  // background-color: rgba(0, 0, 0, 0.1);

}

.darken {
  background-color: rgba(0, 0, 0, 0.1) !important;
  /* Adjust the background color as needed */
}

.lighten {
  background-color: rgba(255, 255, 255, 0.1) !important;

  /* Adjust the background color as needed */
  article {
    p {
      a {

        text-decoration-style: dotted !important;
        text-decoration-line: 1px;

        // color:black;
        color: $orange !important;
      }
    }
  }
}

.darken-2 {
  background-color: rgba(0, 0, 0, 0.3) !important;
  /* Adjust the background color as needed */

}

.darken-3 {
  background-color: rgba(0, 0, 0, 0.5) !important;
  /* Adjust the background color as needed */
}

.lighten-2 {
  background-color: rgba(255, 255, 255, 0.4) !important;
  /* Adjust the background color as needed */
}

.lighten-3 {
  background-color: rgba(255, 255, 255, 0.6) !important;
  /* Adjust the background color as needed */
  .rounded-left {
    border-top-left-radius: 10px; /* Adjust as needed */
    border-bottom-left-radius: 10px;
  }
}

.center {
  margin: auto;
  width: 50%;
  // border: 3px solid green;
  // padding: 10px;
}

.border-gradient-green {
  border-image-source: linear-gradient(to left, #00C853, #B2FF59);
}

.nav-link.white {
  color: white !important;

}

.nav-link.black {
  color: black !important;
}

.course-nav-link {
  color: grey;
  text-decoration: none;
  padding-left: 8px !important;
  border-left: 8px solid #F8F9FA;
}

.course-nav-link.active, .course-nav-link.active:hover {
  color: black !important;
  // font-weight: 500;
  padding-left: 5px !important;
  border-left: 3px solid orangered;
}

.course-nav-item {
  color: grey;
  text-decoration: none;
  li a {
    padding-left: 5px !important;
    border-left: 3px none !important;
    display: inline-block;
    width: 100%;
  }
}

.course-nav-item.active {
  color: black !important;
}

.course-nav-item:hover {
  color: black;
}

.course-nav-link:hover {
  color: black !important;
  // font-weight: 500;
  padding-left: 5px !important;
  border-left: 3px solid grey;
}

.course-heading {
  text-transform: capitalize;
  font-size: 13pt !important;
  font-style: normal !important;
  font-weight: 500 !important; 
  font-family:  "Lato", sans-serif;
  padding-top: 20px !important;
}

.background-wrapper {
  position: relative;
  min-height: 100vh;
  background-color: black; /* Fallback color */
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  opacity: 0.6; /* Set the opacity of the image */
}

// .text-small {
//   font-size: 8pt !important;
// }

.background-img {
  position: absolute;
  // top: 0;
  // left: 0;
  // right: 0;
  // bottom: 0;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
  z-index:  -1;
  // opacity: 0.6; /* Set the opacity of the image */
}

.blog {
  grid-area: blog;
}

.video {
  grid-area: video;
}

.project {
  grid-area: project;
}

.robot {
  grid-area: robot;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: minmax(100px, auto);
  grid-template-areas:
    "blog blog blog robot"
    "blog blog blog project"
    "blog blog blog video"
    "blog blog blog .";
  gap: 15px; /* Adds 20px space between rows and columns */
}

.textbox-3-line {
  // width: 300px;                     /* Set a fixed width */
  height: calc(1.5em * 3);          /* The height is 3 lines tall, where 1.2em is the approximate line height */
  display: -webkit-box;             /* Required for truncation to work in WebKit browsers */
  line-clamp: 3;                    
  -webkit-line-clamp: 3;            /* Limit the text to 3 lines */
  -webkit-box-orient: vertical;     /* Orient the box vertically */
  overflow: hidden;                 /* Hide any overflowed text */
  text-overflow: ellipsis;          /* Show ellipsis when the text overflows */
  white-space: normal;              /* Allow text to break to the next line */
}

/* Apply border to the table */
// 
.events table {
  // border-collapse: collapse; /* Ensures borders are collapsed for a cleaner look */
  width: 100%; /* Optional: adjust the table width */
  // border-radius: 5px; /* Optional: adds rounded corners */
  // overflow: hidden; 
  
}

/* Apply border to table cells */
.events table th,
.events table td {
  border: 1px solid #DDD; /* Adds border to table cells */
  padding: 8px; /* Optional: adds padding inside cells */
}
.events table td a {
  color: #000;
  text-emphasis: bold;
  text-decoration: none;
}

/* Style the first cell in each row */
.events table td:first-child {
  background-color: #EEE;
}

// Truncate lines

.text-truncate-1-lines {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;

  line-height: 1.5em; /* Set line height */
  min-height: 1.5em; /* 3 lines × 1.5em */
  text-overflow: ellipsis; /* Optional: Add ellipsis for overflowing text */
  display: -webkit-box; /* For multi-line truncation (optional) */
  -webkit-box-orient: vertical; /* Required for -webkit-line-clamp */
  white-space: normal; /* Allow text to wrap */
}

.text-truncate-2-lines {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  line-height: 1.5em; /* Set line height */
  min-height: 3em; /* 2 lines × 1.5em */
  text-overflow: ellipsis; /* Optional: Add ellipsis for overflowing text */
  display: -webkit-box; /* For multi-line truncation (optional) */
  -webkit-box-orient: vertical; /* Required for -webkit-line-clamp */
  white-space: normal; /* Allow text to wrap */

}

.text-truncate-3-lines {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;

  line-height: 1.5em; /* Set line height */
  min-height: 4.5em; /* 3 lines × 1.5em */
  overflow: hidden; /* Prevent additional text overflow */
  text-overflow: ellipsis; /* Optional: Add ellipsis for overflowing text */
  display: -webkit-box; /* For multi-line truncation (optional) */
  -webkit-box-orient: vertical; /* Required for -webkit-line-clamp */
  white-space: normal; /* Allow text to wrap */
  
}

.text-truncate-4-lines {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;

  line-height: 1.5em; /* Set line height */
  min-height: 6em; /* 3 lines × 1.5em */
  overflow: hidden; /* Prevent additional text overflow */
  text-overflow: ellipsis; /* Optional: Add ellipsis for overflowing text */
  display: -webkit-box; /* For multi-line truncation (optional) */
  -webkit-box-orient: vertical; /* Required for -webkit-line-clamp */
  white-space: normal; /* Allow text to wrap */

}

.text-truncate-5-lines {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;

  line-height: 1.5em; /* Set line height */
  min-height: 7.5em; /* 5 lines × 1.5em */
  text-overflow: ellipsis; /* Optional: Add ellipsis for overflowing text */
  display: -webkit-box; /* For multi-line truncation (optional) */
  -webkit-box-orient: vertical; /* Required for -webkit-line-clamp */
  white-space: normal; /* Allow text to wrap */

}

.img-container {
  height: 200px; /* Fixed height for the container */
  width: 100%;   /* Full width of the parent */
  display: flex; /* Enables flexbox for centering */
  justify-content: center; /* Centers the image horizontally */
  align-items: center; /* Centers the image vertically */
}

.img-container img {
  max-height: 100%; /* Image scales to fit within the container height */
  max-width: 100%;  /* Image scales to fit within the container width */
  object-fit: contain; /* Ensures the entire image is visible without distortion */
  display: block; /* Removes inline spacing */
}

.text-grey {
  color: $clr-darkgrey;
}

.current_page {
  border-left: orange 2px solid;
  padding-left: 5px;
  margin-left: -5px;

  // border-left-style: solid 2px orange;
  // background-color: red;
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-text {
  visibility: hidden;
  width: 200px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position above the term */
  left: 50%;
  margin-left: -100px; /* Center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.bg-ocre {
  background-color: #ffbf002c;
}