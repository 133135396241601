$kevred: #C22032;
$orange: #EB642B;
$ocre: #FFCA29;
$bg-card-blue: #20445A;
$bg-card-green: #254D26;
$bg-card-red: #59191C;
$bg-card-orange: #774118;

.bg-card-blue {
  background-color: $bg-card-blue !important;
  color: white !important;
}

.bg-card-green {
  background-color: $bg-card-green !important;
  color: white !important;
}

.bg-card-red {
  background-color: $bg-card-red !important;
  color: white !important;
}

.bg-card-orange {
  background-color: $bg-card-orange !important;
  color: white !important;
}

.transparent-white-text {
  color: rgba(255, 255, 255, 0.75);
}

.kevred {
    color: $kevred;
}

.bg-ocre {
  background-color: $ocre;
}

.ocre {
  color: $ocre !important;
}

.btn-ocre {
  background-color: $ocre;
  color: $ocre !important;

}

.bg-white {
  background-color: #ffffff;
}

$clr-blue: #108BB9;
$dark-blue: #072441;
$clr-black: #222222;
$clr-grey: #e9ecef;
$clr-lightgrey: #fafafa;
$clr-red: #ff0000;
$clr-red-hover: #ffeeee;
$clr-text-lg: #A9A9A9;
$clr-nav-background: #ffffff;
$clr-translucent: rgba(255,255,255,0.9);
$clr-orange: #FF5800;
$clr-darkgrey: #888888;
$clr-purple: #e83e8c;
$clr-green: #599C60;

.kevgreen {
  color: $clr-green;
}

/* import only the necessary Bootstrap files */
// @import "bootstrap/scss/functions";
// @import "bootstrap/scss/variables";
// /* -------begin customization-------- */
// /* change the primary theme color, and add a new theme color */
// $theme-colors: (
//   orange: $clr-orange, // orange
//   cyan: $clr-blue // cyan
// );
// /* -------end customization-------- */
// /* finally, import Bootstrap to set the changes! */
// @import "bootstrap";

$clr-blue: #108BB9;
$dark-blue: #072441;
$clr-black: #222222;
// $clr-grey: #e9ecef;
$clr-grey: #f1f2f3;
$clr-red: #ff0000;
$clr-red-hover: #ffeeee;
$clr-text-lg: #A9A9A9;
$clr-nav-background: #ffffff;
$clr-translucent: rgba(255,255,255,0.9);
$clr-orange: #FF5800;
$clr-darkgrey: #888888;
$clr-selector-grey: #424242;

$clr-teal-100: #3F8F92;
$clr-teal-200: #398083;
$clr-teal-300: #337477;
$clr-teal-400: #2F696B;
$clr-teal-500: #2A5E5F;
$clr-teal-600: #265556;
$clr-teal-700: #214D4E;
$clr-teal-800: #1E4546;
$clr-teal-900: #1C3E40;

$clr-fire-red-100: #EA4026;
$clr-fire-red-200: #D33A21;
$clr-fire-red-300: #BF341F;
$clr-fire-red-400: #AB301B;
$clr-fire-red-500: #9B2A18;
$clr-fire-red-600: #8B2616;
$clr-fire-red-700: #7D2215;
$clr-fire-red-800: #701F12;
$clr-fire-red-900: #661C10;



$clr-tangerine-100: #F09937;
$clr-tangerine-200: #D88A32;
$clr-tangerine-300: #C37C2D;
$clr-tangerine-400: #B07029;
$clr-tangerine-500: #9E6525;
$clr-tangerine-600: #8E5C21;
$clr-tangerine-700: #80521D;
$clr-tangerine-800: #74491B;
$clr-tangerine-900: #684118;

$clr-stone-100: #939392;
$clr-stone-200: #848485;
$clr-stone-300: #777777;
$clr-stone-400: #6B6B6B;
$clr-stone-500: #606160;
$clr-stone-600: #585757;
$clr-stone-700: #4F4F4F;
$clr-stone-800: #474746;
$clr-stone-900: #40403F;

$clr-banana-100: #F6C142;
$clr-banana-200: #DDAF3C;
$clr-banana-300: #C69D35;
$clr-banana-400: #B38E31;
$clr-banana-500: #A1802B;
$clr-banana-600: #917327;
$clr-banana-700: #836923;
$clr-banana-800: #775E21;
$clr-banana-900: #6A541C;

$clr-purple-100: #872450;
$clr-purple-200: #7A2149;
$clr-purple-300: #6E1D41;
$clr-purple-400: #631B3B;
$clr-purple-500: #5A1735;
$clr-purple-600: #51152F;
$clr-purple-700: #48142B;
$clr-purple-800: #411227;
$clr-purple-900: #3B1023;

$clr-royal-blue-100: #21528E;
$clr-royal-blue-200: #1E4A80;
$clr-royal-blue-300: #1B4373;
$clr-royal-blue-400: #193C68;
$clr-royal-blue-500: #16365D;
$clr-royal-blue-600: #133155;
$clr-royal-blue-700: #122C4C;
$clr-royal-blue-800: #102744;
$clr-royal-blue-900: #0F243E;

.purple {
  color: $clr-purple;
}

.white-text {
  color: white;
  fill: white;
}
.teal-100 {
  background-color: $clr-teal-100;
}
.teal-200 {
  background-color: $clr-teal-200;
}
.teal-300 {
  background-color: $clr-teal-300;
}
.teal-400 {
  background-color: $clr-teal-400;
}
.teal-500 {
  background-color: $clr-teal-500;
}
.teal-600 {
  background-color: $clr-teal-600;
}
.teal-700 {
  background-color: $clr-teal-700;
}
.teal-800 {
  background-color: $clr-teal-800;
}
.teal-900 {
  background-color: $clr-teal-900;
}
.fire-red-100 {
  background-color: $clr-fire-red-100;
}
.fire-red-200 {
  background-color: $clr-fire-red-200;
}
.fire-red-300 {
  background-color: $clr-fire-red-300;
}
.fire-red-400 {
  background-color: $clr-fire-red-400;
}
.fire-red-500 {
  background-color: $clr-fire-red-500;
}
.fire-red-600 {
  background-color: $clr-fire-red-600;
}
.fire-red-700 {
  background-color: $clr-fire-red-700;
}
.fire-red-800 {
  background-color: $clr-fire-red-800;
}
.fire-red-900 {
  background-color: $clr-fire-red-900;
}

.text-tangerine {
  color: $clr-tangerine-100;
}

.text-teal {
  color: $clr-teal-100;
}


.tangerine-100 {
  background-color: $clr-tangerine-100;
}
.tangerine-200 {
  background-color: $clr-tangerine-200;
}
.tangerine-300 {
  background-color: $clr-tangerine-300;
}
.tangerine-400 {
  background-color: $clr-tangerine-400;
}
.tangerine-500 {
  background-color: $clr-tangerine-500;
}
.tangerine-600 {
  background-color: $clr-tangerine-600;
}
.tangerine-700 {
  background-color: $clr-tangerine-700;
}
.tangerine-800 {
  background-color: $clr-tangerine-800;
}
.tangerine-900 {
  background-color: $clr-tangerine-900;
}
.stone-100 {
  background-color: $clr-stone-100;
}
.stone-200 {
  background-color: $clr-stone-200;
}
.stone-300 {
  background-color: $clr-stone-300;
}
.stone-400 {
  background-color: $clr-stone-400;
}
.stone-500 {
  background-color: $clr-stone-500;
}
.stone-600 {
  background-color: $clr-stone-600;
}
.stone-700 {
  background-color: $clr-stone-700;
}
.stone-800 {
  background-color: $clr-stone-800;
}
.stone-900 {
  background-color: $clr-stone-900;
}
.banana-100 {
  background-color: $clr-banana-100;
}
.banana-200 {
  background-color: $clr-banana-200;
}
.banana-300 {
  background-color: $clr-banana-300;
}
.banana-400 {
  background-color: $clr-banana-400;
}
.banana-500 {
  background-color: $clr-banana-500;
}
.banana-600 {
  background-color: $clr-banana-600;
}
.banana-700 {
  background-color: $clr-banana-700;
}
.banana-800 {
  background-color: $clr-banana-800;
}
.banana-900 {
  background-color: $clr-banana-900;
}

.purple-100 {
  background-color: $clr-purple-100;
}
.purple-200 {
  background-color: $clr-purple-200;
}
.purple-300 {
  background-color: $clr-purple-300;
}
.purple-400 {
  background-color: $clr-purple-400;
}
.purple-500 {
  background-color: $clr-purple-500;
}
.purple-600 {
  background-color: $clr-purple-600;
}
.purple-700 {
  background-color: $clr-purple-700;
}
.purple-800 {
  background-color: $clr-purple-800;
}
.purple-900 {
  background-color: $clr-purple-900;
}

.royal-blue-100 {
  background-color: $clr-royal-blue-100;
}
.royal-blue-200 {
  background-color: $clr-royal-blue-200;
}
.royal-blue-300 {
  background-color: $clr-royal-blue-300;
}
.royal-blue-400 {
  background-color: $clr-royal-blue-400;
}
.royal-blue-500 {
  background-color: $clr-royal-blue-500;
}
.royal-blue-600 {
  background-color: $clr-royal-blue-600;
}
.royal-blue-700 {
  background-color: $clr-royal-blue-700;
}
.royal-blue-800 {
  background-color: $clr-royal-blue-800;
}
.royal-blue-900 {
  background-color: $clr-royal-blue-900;
}

.bg-blue {
  // background-color: $clr-blue;
  background-color: $clr-royal-blue-100;
}

.orange {
  color: $clr-orange;
}

$clr-text-color: #4a4a4a;

.text-color {
  color: $clr-text-color;
}

.light-grey {
  color: $clr-lightgrey;
}
.dark-grey {
  color: $clr-darkgrey !important;
}