// debug.css
// for debugging responsive design issues

.debug {
  position: absolute;
  top:0;
  left:0;
  p {
    width: 100px;
    // height: 50px;
    background-color: rgb(0, 255, 229);
  }
}
// extra small
@media only screen and (max-width: 575px) {
  .debug-extra-small {
    position: absolute;
    top:0;
    left:0;
    visibility: visible;
  }
  .debug-small {
    height: 0px;
    width: 0px;
    visibility: collapse;
  }
  .debug-medium {
    height: 0px;
    width: 0px;
    visibility: collapse;
  }
  .debug-large {
    height: 0px;
    width: 0px;
    visibility: collapse;
  }
  .debug-extra-large {
    height: 0px;
    width: 0px;
    visibility: collapse;
  }
  .debug-oversize {
    height: 0px;
    width: 0px;
    visibility: collapse;
  }
}

// small
@media only screen and (min-width: 576px) {
  .debug-extra-small {
    visibility: collapse;
    height: 0px;
    width: 0px;
  }
  .debug-small {
    position: absolute;
    top:0;
    left:0;
    visibility: visible;
  }
  .debug-medium {
    visibility: collapse;
    height: 0px;
    width: 0px;
  }
  .debug-large {
    visibility: collapse;
    height: 0px;
    width: 0px;
  }
  .debug-extra-large {
    visibility: collapse;
    height: 0px;
    width: 0px;
  }
  .debug-oversize {
    height: 0px;
    width: 0px;
    visibility: collapse;
  }
}

// medium
@media only screen and (min-width: 768px) {
  .debug-extra-small {
    height: 0px;
    width: 0px;
    visibility: collapse;
  }
  .debug-small {
    height: 0px;
    width: 0px;
    visibility: collapse;
  }
  .debug-medium {
    position: absolute;
    top:0;
    left:0;
    visibility: visible;
  }
  .debug-large {
    height: 0px;
    width: 0px;
    visibility: collapse;
  }
  .debug-extra-large {
    height: 0px;
    width: 0px;
    visibility: collapse;
  }
  .debug-oversize {
    height: 0px;
    width: 0px;
    visibility: collapse;
  }
}

// large
@media only screen and (min-width: 992px) {
  .debug-extra-small {
    height: 0px;
    width: 0px;
    visibility: collapse;
  }
  .debug-small {
    height: 0px;
    width: 0px;
    visibility: collapse;
  }
  .debug-medium {
    height: 0px;
    width: 0px;
    visibility: collapse;
  }
  .debug-large {
    position: absolute;
    top:0;
    left:0;
    visibility: visible;
  }
  .debug-extra-large {
    height: 0px;
    width: 0px;
    visibility: collapse;
  }
  .debug-oversize {
    height: 0px;
    width: 0px;
    visibility: collapse;
  }
}

// extra large
@media only screen and (min-width: 1200px) {
  .debug-extra-small {
    height: 0px;
    width: 0px;
    visibility: collapse;
  }
  .debug-small {
    height: 0px;
    width: 0px;
    visibility: collapse;
  }
  .debug-medium {
    height: 0px;
    width: 0px;
    visibility: collapse;
  }
  .debug-large {
    height: 0px;
    width: 0px;
    visibility: collapse;
  }
  .debug-extra-large {
    position: absolute;
    top:0;
    left:0;
    visibility: visible;
  }
  .debug-oversize {
    height: 0px;
    width: 0px;
    visibility: collapse;
  }
}

// oversize
@media only screen and (min-width: 1400px) {
  .debug-extra-small {
    height: 0px;
    width: 0px;
    visibility: collapse;
  }
  .debug-small {
    height: 0px;
    width: 0px;
    visibility: collapse;
  }
  .debug-medium {
    height: 0px;
    width: 0px;
    visibility: collapse;
  }
  .debug-large {
    height: 0px;
    width: 0px;
    visibility: collapse;
  }
  .debug-extra-large {
    height: 0px;
    width: 0px;
    visibility: collapse;
  }
  .debug-oversize {
    position: absolute;
    top:0;
    left:0;
    visibility: visible;
  }
}
